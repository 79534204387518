import React, { useState } from "react";
import "./styles.scss";

const CompetitorStatCards = () => {
  const cards = [
    {
      title: "1. Identity Network Size",
      description:
        "Identify more visitors with purchasing intent and send emails that generate revenue.",
      additionalInfo:
        "Our identity network allows you to increase recognition and drive personalized engagement.",
      backTitle: "Vs. Retention.com",
      backInfo:
        "Cookie-based and poor 3rd party data leads to emails bouncing or reported as spam.",
      backgroundImage: "/images/abx-competitor/Group 2300.svg",
      backBackgroundImage: "/images/abx-competitor/Group 2300 (1).svg",
    },
    {
      title: "2. Future-proofing revenue",
      description:
        "Since 2010, we’ve invested in building the highest identification rates on the market while staying compliant.",
      additionalInfo:
        "Stay ahead with compliance solutions tailored to evolving regulations.",
      backTitle: "Vs. Retention.com",
      backInfo:
        "Current and upcoming privacy laws put cookie-based technologies at risk.",
      backgroundImage: "/images/abx-competitor/Frame (10).svg",
      backBackgroundImage: "/images/abx-competitor/Frame (16).svg",
    },
    {
      title: "3. Full-service and self-service",
      description:
        "We handle strategy, creatives, and copy, or choose to manage your own campaigns with Wunderkind Studio.",
      additionalInfo:
        "Choose your preferred service model to maximize your marketing efforts.",
      backTitle: "Vs. Retention.com",
      backInfo:
        "Self-service only. Campaigns set up and driving performance are on you.",
      backgroundImage: "/images/abx-competitor/Group 2300 (1).svg",
      backBackgroundImage: "/images/abx-competitor/Group 2300 (1).svg",
    },
    {
      title: "4. Global Coverage",
      description:
        "Drive revenue in all your key markets — compliant with CAN-SPAM, CASL, and GDPR.",
      additionalInfo:
        "Expand your reach while adhering to global standards and best practices.",
      backTitle: "Vs. Retention.com",
      backInfo: "Only works in the US — not compliant in Canada or Europe",
      backgroundImage: "/images/abx-competitor/Frame (11).svg",
      backBackgroundImage: "/images/abx-competitor/Frame (17).svg",
    },
    {
      title: "5. Superior deliverability",
      description:
        "Adherence to opt-in practices results in lower spam rates and higher inbox placement.",
      additionalInfo:
        "Achieve unparalleled deliverability to ensure your campaigns reach the right audience.",
      backTitle: "Vs. Retention.com",
      backInfo:
        "Emails users outside your lists, damaging your deliverability score.",
        backgroundImage: "/images/abx-competitor/Pattern 10 (5).svg",
        backBackgroundImage: "/images/abx-competitor/Group 2300 (1).svg",

    },
    {
      title: "6. Integrate with your tech stack",
      description:
        "Platform-agnostic solution that integrates with any existing email infrastructure.",
      additionalInfo:
        "Seamlessly connect with your tools for a smoother workflow.",
      backTitle: "Vs. Retention.com",
      backInfo:
        "Offers plenty of integrations but mostly works with clients running on Shopify and Klaviyo.",
        backgroundImage: "/images/abx-competitor/Pattern 06.svg",
        backBackgroundImage: "/images/abx-competitor/Frame (19).svg",

    },
  ];

  const [flippedCards, setFlippedCards] = useState(
    new Array(cards.length).fill(false)
  );

  const toggleCard = index => {
    setFlippedCards(prevState =>
      prevState.map((flipped, i) => (i === index ? !flipped : flipped))
    );
  };

  return (
    <div className="abx-competitor-stat-section-card-container">
      {cards.map((card, index) => (
        <div
          className={`abx-competitor-stat-section-card ${
            flippedCards[index] ? "flipped" : ""
          }`}
          key={index}
        >
          {/* Front Side */}
          <div className="card-front">
            <h3>{card.title}</h3>
            <img
              className="abx-competitor-stat-section-card-toggle"
              src="/images/swap-hover-icon-black.svg"
              alt="plus"
              onClick={() => toggleCard(index)}
            />
            <p>{card.description}</p>
            <img
              className="abx-competitor-stat-section-card-image"
              src={card.backgroundImage}
              alt="card-background"
            />
          </div>

          {/* Back Side */}
          <div className="card-back">
            <h3>{card.backTitle}</h3>
            <img
              className="abx-competitor-stat-section-card-toggle"
              src="/images/swap-hover-icon-black.svg"
              alt="minus"
              onClick={() => toggleCard(index)}
            />
            <p>{card.backInfo}</p>
            <img
              className="abx-competitor-stat-section-card-image"
              src={card.backBackgroundImage}
              alt="card-background"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompetitorStatCards;
